import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import Timecode from './Timecode'
import { TextField } from '@mui/material';
import { FieldDescription, FieldProps } from "@camberi/firecms";
import { withStyles, makeStyles } from '@mui/styles';
import Slider from '@mui/material/Slider';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/AddAlarm';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import {formatTimecode} from './Utils'

import './reset.css'
import './defaults.css'
import './range.css'
import './App.css'

let pressTimer

const useStyles = makeStyles( theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function valuetext(value) {
  return `${value}°C`;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const TrimmerSlider = withStyles({
  root: {
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 30,
    width: 2,
    backgroundColor: '#000',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: 0,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    borderRadius: 0,
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

const VideoEditor = ({
  property,
  value,
  setValue,
  customProps,
  touched,
  error,
  isSubmitting,
  context, // the rest of the entity values here
  ...props
}) => {

  const classes = useStyles();
  const [valueTime, setValueTime] = React.useState([0, 10000]);
  const [delay, setDelay] = useState(100);
  const [fromBackwardIsRunning, setFromBackwardIsRunning] = useState(false);
  const [fromForwardIsRunning, setFromForwardIsRunning] = useState(false);
  const [toBackwardIsRunning, setToBackwardIsRunning] = useState(false);
  const [toForwardIsRunning, setToForwardIsRunning] = useState(false);

  useInterval(() => {
    setPlaying(false)
    let goto = parseFloat(valueTime[0]/10000) - (1 / 60) / parseFloat(duration)
      setValueTime([goto*10000, valueTime[1]])
      player.current.seekTo(goto)
  }, fromBackwardIsRunning ? delay : null);

  const handleFromBackwardMouseDown = e => {
    setPlaying(false)
    setFromBackwardIsRunning(true)
  };

  const handleFromBackwardMouseUp = e => {
    setFromBackwardIsRunning(false)
    setPlaying(true)
  }

  useInterval(() => {
    setPlaying(false)
    let goto = parseFloat(valueTime[0]/10000) + (1 / 60) / parseFloat(duration)
      setValueTime([goto*10000, valueTime[1]])
      player.current.seekTo(goto)
  }, fromForwardIsRunning ? delay : null);

  const handleFromForwardMouseDown = e => {
    setPlaying(false)
    setFromForwardIsRunning(true)
  };

  const handleFromForwardMouseUp = e => {
    setFromForwardIsRunning(false)
    setPlaying(true)
  }

  useInterval(() => {
    setPlaying(false)
    let goto = parseFloat(valueTime[1]/10000) - (1 / 60) / parseFloat(duration)
      setValueTime([valueTime[0], goto*10000])
      player.current.seekTo(goto)
  }, toBackwardIsRunning ? delay : null);

  const handleToBackwardMouseDown = e => {
    setPlaying(false)
    setToBackwardIsRunning(true)
  };

  const handleToBackwardMouseUp = e => {
    setToBackwardIsRunning(false)
    setPlaying(true)
  }

  useInterval(() => {
    setPlaying(false)
    let goto = parseFloat(valueTime[1]/10000) + (1 / 60) / parseFloat(duration)
      setValueTime([valueTime[0], goto*10000])
      player.current.seekTo(goto)
  }, toForwardIsRunning ? delay : null);

  const handleToForwardMouseDown = e => {
    setPlaying(false)
    setToForwardIsRunning(true)
  };

  const handleToForwardMouseUp = e => {
    setToForwardIsRunning(false)
    setPlaying(true)
  }

  const handleChange = (event, newValue) => {
    if (newValue[0] != valueTime[0]) {
      setPlaying(false)
    }

    setValueTime(newValue)
  };

  useEffect(() => {
    return () => {
      setPlaying(false)
    };
  }, []);

  const handleChangeCommitted = (event, newValue) => {
    if (!playing) {
      const goto = parseFloat(newValue[0])/10000
      player.current.seekTo(goto)
      setPlaying(true)
    }
  }

  const player = useRef(null);
  const inputEl = useRef(null);

  const [url, setUrl] = useState(null);
  const [pip, setPip] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [controls, setControls] = useState(false);
  const [light, setLight] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [loop, setLoop] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const load = url => {
    setUrl(url)
    setPlayed(0)
    setLoaded(0)
    setPip(false)
    setFrom(0)
    setTo(0)
  }

  const handleTimeValidation = () => {
    context.values.source["fromEditor"] = formatTimecode(duration, valueTime[0]/10000)
    context.values.source["toEditor"] = formatTimecode(duration, valueTime[1] / 10000)

    setPlaying(false)
  }

  const handlePlayPause = () => {
    if (!loaded && value) {
      load(value)
    }
    setPlaying(!playing)
  }

  const handleReset = () => {
    setValueTime([0, 10000])
  }

  const handleToggleControls = () => {
    setControls(!controls)
    setUrl(null)
    load(url)
  }

  const handleToggleLight = () => {
    setLight(!light)
  }

  const handleToggleLoop = () => {
    setLoop(!loop)
  }

  const handleVolumeChange = e => {
    setVolume(parseFloat(e.target.value))
  }

  const handleToggleMuted = () => {
    setMuted(!muted)
  }

  const handleSetPlaybackRate = e => {
    setPlaybackRate(parseFloat(e.target.value))
  }

  const handleTogglePIP = () => {
    setPip(!pip)
  }

  const handlePlay = () => {
    console.log('onPlay')
    setPlaying(true)
  }

  const handleEnablePIP = () => {
    console.log('onEnablePIP')
    setPip(true)
  }

  const handleDisablePIP = () => {
    console.log('onDisablePIP')
    setPip(false)
  }

  const handlePause = () => {
    console.log('onPause')
    setPlaying(false)
  }

  const handleSeekMouseDown = e => {
    setSeeking(true)
  }

  const handleSeekChange = e => {
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = e => {
    setSeeking(false)
    player.current.seekTo(parseFloat(e.target.value))

    //10s
    //player.current.seekTo(0.03984063745)

    //10s et nbImages=30 =>  (10 + (nbImages/60)) /duration
    // => 0.04183266932
    //const goto = (11 + (12/60)) / 251
    //player.current.seekTo(goto)

    //11s
    //player.current.seekTo(0.0438247012)

  }

  const handleSeekFromMouseDown = e => {}

  const handleSeekFromChange = e => {
    setFrom(e.target.value)
  }

  const handleSeekFromMouseUp = e => {
    setFrom(e.target.value)
    console.log('FROM e.target.value',parseFloat( e.target.value));
  }

  const handleSeekToMouseDown = e => { }

  const handleImageSeekFromRecordMouseDown = e => {
    setValueTime([played*10000, valueTime[1]])
  }

  const handleImageSeekToRecordMouseDown = e => {
    setValueTime([valueTime[0], played * 10000])
  }

  const handleImageSeekFromPlayMouseDown = e => {
    setPlaying(false)
    player.current.seekTo(valueTime[0]/10000)
  }

  const handleImageSeekToPlayMouseDown = e => {
    setPlaying(false)
    player.current.seekTo(valueTime[1]/10000)
  }

  const handleSeekToChange = e => {
    setValueTime([valueTime[0], e.target.value])
  }

  const handleSeekToMouseUp = e => {
    setValueTime([valueTime[0], e.target.value])
    console.log('TO e.target.value',parseFloat( e.target.value));
  }

  const handleProgress = state => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played)

      if (valueTime[1] > 0 && played > parseFloat(valueTime[1]/10000)) {
        player.current.seekTo(parseFloat(valueTime[0]/10000))
      }
    }
  }

  const handleEnded = () => {
    console.log('onEnded')
    setPlaying(loop)
  }

  const handleDuration = (duration) => {
    setDuration(duration)
    const from = context.values.source.from
    const to = context.values.source.to
    let fromTime = 0
    let toTime = 1

    if (from && from != '') {
      const arValues = from.split(':')
      const h = parseInt(arValues[0])
      const m = parseInt(arValues[1])
      const arImageValues = arValues[2].split('.')
      const s = parseInt(arImageValues[0])
      let i = 0
      if (arImageValues.length == 2) {
        i = parseInt(arImageValues[1])
      }

      fromTime = (h * 3600 + m * 60 + s + i / 60) / duration
    }
    if (to && to != '') {
      const arValues = to.split(':')
      const h = parseInt(arValues[0])
      const m = parseInt(arValues[1])
      const arImageValues = arValues[2].split('.')
      const s = parseInt(arImageValues[0])
      let i = 0
      if (arImageValues.length == 2) {
        i = parseInt(arImageValues[1])
      }
      toTime = (h * 3600 + m * 60 + s + i/60) / duration
    }
    setValueTime([fromTime * 10000, toTime * 10000])
  }

  const renderLoadButton = (url, label) => {
    return (
      <button onClick={() => load(url)}>
        {label}
      </button>
    )
  }

  return (
    <div className='app'>
      <section>
        <TextField
          required={property.validation?.required}
          error={!!error}
          disabled={isSubmitting}
          label={property.title}
          value={value ?? ""}
          onChange={evt => {
            setValue(evt.target.value);
          }}
          onBlur={evt => {
            setUrl(evt.target.value)
          }}
          helperText={error}
          fullWidth
          variant={"filled"}
        />

        <FieldDescription property={property} />
      </section>
      <section>
        <div className='player-wrapper'>
          <ReactPlayer
            ref={player}
            className='react-player'
            width='100%'
            height='100%'
            url={url}
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            progressInterval={16}
            onReady={() => console.log('onReady')}
            onStart={() => { console.log('onStart'); player.current.seekTo(valueTime[0]/10000) }}
            onPlay={handlePlay}
            onEnablePIP={handleEnablePIP}
            onDisablePIP={handleDisablePIP}
            onPause={handlePause}
            onBuffer={() => console.log('onBuffer')}
            onSeek={e => console.log('onSeek', e)}
            onEnded={handleEnded}
            onError={e => console.log('onError', e)}
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
        </div>
        <div style={{ margin: '30px 50px 0', display: 'flex', justifyContent: 'space-between' }}>
          <Fab size="small" color="primary" aria-label="add" className={classes.margin} onMouseDown={handleImageSeekFromRecordMouseDown}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="primary" aria-label="add" className={classes.margin} onClick={handlePlayPause}>
            {!playing ?
              <PlayIcon />
              :
              <PauseIcon />
            }
          </Fab>
          <Fab size="small" color="primary" aria-label="add" className={classes.margin} onMouseDown={handleImageSeekToRecordMouseDown}>
            <AddIcon />
          </Fab>
        </div>
        <div style={{ marginTop: '10px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button onMouseDown={handleFromBackwardMouseDown} onMouseUp={handleFromBackwardMouseUp}>-</Button>
              <Button><Timecode duration={duration} position={valueTime[0]/10000} /></Button>
              <Button onMouseDown={handleFromForwardMouseDown} onMouseUp={handleFromForwardMouseUp}>+</Button>
            </ButtonGroup>
          </div>
          <Timecode duration={duration} position={played} />
          <div>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button onMouseDown={handleToBackwardMouseDown} onMouseUp={handleToBackwardMouseUp}>-</Button>
              <Button><Timecode duration={duration} position={valueTime[1]/10000} /></Button>
              <Button onMouseDown={handleToForwardMouseDown} onMouseUp={handleToForwardMouseUp}>+</Button>
            </ButtonGroup>
          </div>
        </div>
        <TrimmerSlider
          min={0}
          max={10000}
          step={1}
          value={valueTime}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          valueLabelDisplay="off"
        />
        <div style={{ margin: '30px 0', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onMouseDown={handleReset}>
            Remettre à zéro
          </Button>
          <Button variant="contained" color="primary" onMouseDown={handleTimeValidation}>
            Valider le temps saisi
          </Button>
        </div>
      </section>
      {/* <section>
        <table>
          <tbody>
            <tr>
              <th>Custom URL</th>
              <td>
                <input ref={inputEl} type='text' placeholder='Enter URL'/>
                <button onClick={() => setUrl(inputEl.current.value)}>Load</button>
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        <h1>Trim your video</h1>
        <p>
          click on the start sequence button or use the slider to choose the start time.
          click on the end sequence button or use the slider to choose the end time.

          If you want to be more precise, use the image by image button for start and end times.
        </p>
      </section> */}
    </div>
  )
}

export default VideoEditor
