import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListSubheader,
  TextField,
  Theme,
  Chip,
} from "@mui/material";
import {
  FieldDescription,
  FieldProps,
  EnumType,
  EnumValues,
  LabelWithIcon,
  useClearRestoreValue,
  CustomChip,
  ArrayEnumPreview,
} from "@camberi/firecms";

interface EmotionLayout {
  id: string;
  title: string;
  color: string;
  children: EmotionLayout[];
}

interface EnumValuesChipProps {
  enumValues: EnumValues;
  enumKey: any;
  small: boolean;
}

type SelectProps<T extends EnumType> = FieldProps<T>;

export default function CustomEmotionField({
  name,
  value,
  setValue,
  customProps,
  error,
  showError,
  disabled,
  autoFocus,
  touched,
  property,
  includeDescription,
}: FieldProps<string[], any, any>) {

  if (!property.of) {
    throw Error("Using wrong component ArrayEnumSelect");
  }

  if (property.of.dataType !== "string" && property.of.dataType !== "number") {
    throw Error("Field misconfiguration: array field of type string or number");
  }

  useClearRestoreValue({
    property,
    value,
    setValue
  });

  const renderSelectGroup = (emotion: EmotionLayout) => {
    const emotions: EmotionLayout[] = emotion.children;
    const items: any = emotions.map((subemotion) => {
      const checked = validValue && value.map(v => v.toString()).includes(subemotion.id);
      //const checked = false;
      console.log('value', value, subemotion);
      return (
        <MenuItem
          key={`form-select-${name}-${subemotion.id}`}
          value={subemotion.id}
          dense={true}
        >
          <Checkbox checked={checked}/>
          <ListItemText primary={
            <Chip
                size="small"
                variant="filled"
                label={subemotion.title}
            />
          }/>
        </MenuItem>
      );
    });
    return [<ListSubheader>{emotion.title}</ListSubheader>, items];
  };

  const emotions: EmotionLayout[] = customProps.emotion

  const validValue = !!value && Array.isArray(value);
  return (

      <FormControl
        variant="filled"
        fullWidth
        required={property.validation?.required}
        error={showError}
        disabled={disabled}
      >
        <InputLabel id={`${name}-multiselect-label`}>
          <LabelWithIcon property={property}/>
        </InputLabel>
      <Select
        multiple
        variant={"filled"}
        labelId={`${name}-multiselect-label`}
        autoFocus={autoFocus}
        value={validValue ? value.map(v => v.toString()) : []}
        disabled={disabled}
        onChange={(evt: any) => {
          let newValue;
          if (property.of?.dataType === "number")
              newValue = evt.target.value ? evt.target.value.map((e: any) => parseFloat(e)) : [];
          else
              newValue = evt.target.value;
          return setValue(
              newValue
          );
        }}
        renderValue={(selected: any) => (
          <ArrayEnumPreview value={selected}
                            name={name}
                            enumValues={{"aaa": "aaa", "bbb": "bbb"}}
                            size={"regular"}/>
        )}
      >
        {emotions.map(emotion => {
          return renderSelectGroup(emotion)
        })}
      </Select>

        {includeDescription &&
            <FieldDescription property={property}/>}
        {showError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
  );
}
