import React from "react";

export function FireCMSLogo() {
  return (
    <svg
      width="232"
      height="232"
      viewBox="0 0 599 599"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <radialGradient
          cx="28.6213569%"
          cy="43.1133328%"
          fx="28.6213569%"
          fy="43.1133328%"
          r="71.5003456%"
          gradientTransform="translate(0.286214,0.431133),rotate(3.343450),scale(1.000000,0.996175),translate(-0.286214,-0.431133)"
          id="radialGradient-1"
        >
          <stop stopColor="#FF5B79" offset="0%" />
          <stop stopColor="#FA5574" offset="28.0930803%" />
          <stop stopColor="#EC4C51" offset="44.7242531%" />
          <stop stopColor="#9543C1" offset="71.4578165%" />
          <stop stopColor="#3857B3" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="53.6205516%"
          cy="47.2473036%"
          fx="53.6205516%"
          fy="47.2473036%"
          r="50.8229649%"
          gradientTransform="translate(0.536206,0.472473),rotate(90.000000),scale(1.000000,1.206631),translate(-0.536206,-0.472473)"
          id="radialGradient-2"
        >
          <stop stopColor="#68294F" stopOpacity="0" offset="0%" />
          <stop
            stopColor="#5E2548"
            stopOpacity="0.04641108"
            offset="75.3503173%"
          />
          <stop stopColor="#0D060B" stopOpacity="0.437431709" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="53.8605015%"
          cy="48.1990423%"
          fx="53.8605015%"
          fy="48.1990423%"
          r="59.9151549%"
          gradientTransform="translate(0.538605,0.481990),rotate(180.000000),scale(1.000000,0.925027),translate(-0.538605,-0.481990)"
          id="radialGradient-3"
        >
          <stop stopColor="#68294F" stopOpacity="0" offset="0%" />
          <stop
            stopColor="#5E2548"
            stopOpacity="0.04641108"
            offset="84.0867343%"
          />
          <stop stopColor="#FF0000" stopOpacity="0.567324765" offset="100%" />
        </radialGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="firecms_logo">
          <circle
            fill="url(#radialGradient-1)"
            cx="299.5"
            cy="299.5"
            r="299.5"
          />
          <circle
            fill="url(#radialGradient-2)"
            cx="299.5"
            cy="299.5"
            r="299.5"
          />
          <circle
            fill="url(#radialGradient-3)"
            cx="299.5"
            cy="299.5"
            r="299.5"
          />
        </g>
      </g>
    </svg>
  );
}
