import React from "react";
import algoliasearch, { SearchClient } from "algoliasearch";
import {
  Authenticator,
  buildCollection,
  buildProperty,
  buildSchema,
  buildEntityCallbacks,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
  performAlgoliaTextSearch,
  FirestoreTextSearchController,
} from "@camberi/firecms";

import {PunchlineCMSApp} from './App/PunchlineCMSApp'

import firebase from "firebase/app";
import CustomColorTextField from "./CustomColorTextField";
import CustomEmotionField from "./CustomEmotionField";

import "typeface-rubik";
import "typeface-space-mono";
import { getMaxListeners } from "process";

import VideoEditor from "./VideoEditor";
import { EmojiEmotionsTwoTone } from "@mui/icons-material";

require("dotenv").config();
if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

//const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
const client = algoliasearch("DPGXWL31ZA", "8ca5737b933a61f843284bac01c2fd96");

const punchlinesIndex = client.initIndex("punchlines");

const textSearchController: FirestoreTextSearchController = ({
  path,
  searchString,
}) => {
  if (path === "punchlines")
    return performAlgoliaTextSearch(punchlinesIndex, searchString);
  return undefined;
};

const firebaseConfig = {
  apiKey: "AIzaSyCdkxyoO_18OSfEvDrKikkg-07CYnMwGNM",
  authDomain: "punchline-4e555.firebaseapp.com",
  projectId: "punchline-4e555",
  storageBucket: "punchline-4e555.appspot.com",
  messagingSenderId: "559810053533",
  appId: "1:559810053533:web:55984f1b606c68d6c3db74",
  measurementId: "G-67Q0LS84VR",
};

const locales = {
  "fr-FR": "French",
  "de-DE": "German",
  "en-US": "English (United States)",
  "es-ES": "Spanish (Spain)",
  "es-419": "Spanish (South America)",
};

const langs = {
  "de": "German",
  "en": "English",
  "es": "Spanish",
  "fr": "French",
  "it": "Italian",
  "pt": "Portuguese",
  "ab": "Abkhaz",
  "aa": "Afar",
  "af": "Afrikaans",
  "ak": "Akan",
  "sq": "Albanian",
  "am": "Amharic",
  "ar": "Arabic",
  "an": "Aragonese",
  "hy": "Armenian",
  "as": "Assamese",
  "av": "Avaric",
  "ae": "Avestan",
  "ay": "Aymara",
  "az": "Azerbaijani",
  "bm": "Bambara",
  "ba": "Bashkir",
  "eu": "Basque",
  "be": "Belarusian",
  "bn": "Bengali",
  "bh": "Bihari",
  "bi": "Bislama",
  "bs": "Bosnian",
  "br": "Breton",
  "bg": "Bulgarian",
  "my": "Burmese",
  "ca": "Catalan; Valencian",
  "ch": "Chamorro",
  "ce": "Chechen",
  "ny": "Chichewa; Chewa; Nyanja",
  "zh": "Chinese",
  "cv": "Chuvash",
  "kw": "Cornish",
  "co": "Corsican",
  "cr": "Cree",
  "hr": "Croatian",
  "cs": "Czech",
  "da": "Danish",
  "dv": "Divehi; Dhivehi; Maldivian;",
  "nl": "Dutch",
  "eo": "Esperanto",
  "et": "Estonian",
  "ee": "Ewe",
  "fo": "Faroese",
  "fj": "Fijian",
  "fi": "Finnish",
  "ff": "Fula; Fulah; Pulaar; Pular",
  "gl": "Galician",
  "ka": "Georgian",
  "el": "Greek, Modern",
  "gn": "Guaraní",
  "gu": "Gujarati",
  "ht": "Haitian; Haitian Creole",
  "ha": "Hausa",
  "he": "Hebrew (modern)",
  "hz": "Herero",
  "hi": "Hindi",
  "ho": "Hiri Motu",
  "hu": "Hungarian",
  "ia": "Interlingua",
  "id": "Indonesian",
  "ie": "Interlingue",
  "ga": "Irish",
  "ig": "Igbo",
  "ik": "Inupiaq",
  "io": "Ido",
  "is": "Icelandic",
  "iu": "Inuktitut",
  "ja": "Japanese",
  "jv": "Javanese",
  "kl": "Kalaallisut, Greenlandic",
  "kn": "Kannada",
  "kr": "Kanuri",
  "ks": "Kashmiri",
  "kk": "Kazakh",
  "km": "Khmer",
  "ki": "Kikuyu, Gikuyu",
  "rw": "Kinyarwanda",
  "ky": "Kirghiz, Kyrgyz",
  "kv": "Komi",
  "kg": "Kongo",
  "ko": "Korean",
  "ku": "Kurdish",
  "kj": "Kwanyama, Kuanyama",
  "la": "Latin",
  "lb": "Luxembourgish, Letzeburgesch",
  "lg": "Luganda",
  "li": "Limburgish, Limburgan, Limburger",
  "ln": "Lingala",
  "lo": "Lao",
  "lt": "Lithuanian",
  "lu": "Luba-Katanga",
  "lv": "Latvian",
  "gv": "Manx",
  "mk": "Macedonian",
  "mg": "Malagasy",
  "ms": "Malay",
  "ml": "Malayalam",
  "mt": "Maltese",
  "mi": "Māori",
  "mr": "Marathi (Marāṭhī)",
  "mh": "Marshallese",
  "mn": "Mongolian",
  "na": "Nauru",
  "nv": "Navajo, Navaho",
  "nb": "Norwegian Bokmål",
  "nd": "North Ndebele",
  "ne": "Nepali",
  "ng": "Ndonga",
  "nn": "Norwegian Nynorsk",
  "no": "Norwegian",
  "ii": "Nuosu",
  "nr": "South Ndebele",
  "oc": "Occitan",
  "oj": "Ojibwe, Ojibwa",
  "cu": "Old Slavonic",
  "om": "Oromo",
  "or": "Oriya",
  "os": "Ossetian, Ossetic",
  "pa": "Panjabi, Punjabi",
  "pi": "Pāli",
  "fa": "Persian",
  "pl": "Polish",
  "ps": "Pashto, Pushto",
  "qu": "Quechua",
  "rm": "Romansh",
  "rn": "Kirundi",
  "ro": "Romanian, Moldavian, Moldovan",
  "ru": "Russian",
  "sa": "Sanskrit (Saṁskṛta)",
  "sc": "Sardinian",
  "sd": "Sindhi",
  "se": "Northern Sami",
  "sm": "Samoan",
  "sg": "Sango",
  "sr": "Serbian",
  "gd": "Scottish Gaelic; Gaelic",
  "sn": "Shona",
  "si": "Sinhala, Sinhalese",
  "sk": "Slovak",
  "sl": "Slovene",
  "so": "Somali",
  "st": "Southern Sotho",
  "su": "Sundanese",
  "sw": "Swahili",
  "ss": "Swati",
  "sv": "Swedish",
  "ta": "Tamil",
  "te": "Telugu",
  "tg": "Tajik",
  "th": "Thai",
  "ti": "Tigrinya",
  "bo": "Tibetan Standard, Tibetan, Central",
  "tk": "Turkmen",
  "tl": "Tagalog",
  "tn": "Tswana",
  "to": "Tonga (Tonga Islands)",
  "tr": "Turkish",
  "ts": "Tsonga",
  "tt": "Tatar",
  "tw": "Twi",
  "ty": "Tahitian",
  "ug": "Uighur, Uyghur",
  "uk": "Ukrainian",
  "ur": "Urdu",
  "uz": "Uzbek",
  "ve": "Venda",
  "vi": "Vietnamese",
  "vo": "Volapük",
  "wa": "Walloon",
  "cy": "Welsh",
  "wo": "Wolof",
  "fy": "Western Frisian",
  "xh": "Xhosa",
  "yi": "Yiddish",
  "yo": "Yoruba",
  "za": "Zhuang, Chuang",
  "zu": "Zulu"
};

const attitudes = {
  sleepy: "Sleepy",
  hungry: "Hungry",
  crazy: "Crazy",
  lazy: "Lazy",
  stressed: "Stressed",
  embarrassed: "Embarrassed",
  clapping: "Clapping",
  nervous: "Nervous",
  scared: "Scared",
  bored: "Bored",
  shrug: "Shrug",
  sad: "Sad",
  confused: "Confused",
  chill_out: "Chill out",
  love: "Love",
  happy: "Happy",
  cry: "Cry",
  lol: "Lol",
  excited: "Excited",
};
const reactions = {
  hello: "Hello",
  ok: "Ok",
  please: "Please",
  thank_you: "Thank you",
  miss_you: "Miss you",
  whatever: "Whatever",
  omg: "OMG",
  awkward: "Awkward",
  ew: "Ew",
  why: "Why",
  wow: "Wow",
  ouch: "Ouch",
  oops: "Oops",
  you_re_welcome: "You're welcome",
  awesome: "Awesome",
  jk: "Jk",
  good_luck: "Good luck",
  duh: "Duh",
  aww: "Aww",
  sigh: "Sigh",
  good_night: "Good night",
  good_morning: "Good morning",
  love: "Love",
  yes: "Yes",
  no: "No",
  lol: "Lol",
  bye: "Bye",
  sorry: "Sorry",
  congratulations: "Congratulations",
  hugs: "Hugs",
  wink: "Wink",
  dance: "Dance",
  smile: "Smile",
  thumbs_up: "Thumbs up",
  clapping: "Clapping",
  high_five: "High five",
  kiss: "Kiss",
  cry: "Cry",
}
const topics = {
  sleepy: "Sleepy",
  hello: "Hello",
  hugs: "Hugs",
  ok: "Ok",
  please: "Please",
  thank_you: "Thank you",
  miss_you: "Miss you",
  wink: "Wink",
  whatever: "Whatever",
  hungry: "Hungry",
  dance: "Dance",
  annoyed: "Annoyed",
  omg: "OMG",
  crazy: "Crazy",
  shrug: "Shrug",
  smile: "Smile",
  awkward: "Awkward",
  ew: "Ew",
  angry: "Angry",
  surprised: "Surprised",
  why: "Why",
  thumbs_up: "Thumbs up",
  wow: "Wow",
  ouch: "Ouch",
  oops: "Oops",
  you_re_welcome: "You're welcome",
  lazy: "Lazy",
  stressed: "Stressed",
  embarrassed: "Embarrassed",
  clapping: "Clapping",
  awesome: "Awesome",
  jk: "Jk",
  good_luck: "Good luck",
  high_five: "High five",
  nervous: "Nervous",
  duh: "Duh",
  aww: "Aww",
  scared: "Scared",
  bored: "Bored",
  sigh: "Sigh",
  kiss: "Kiss",
  sad: "Sad",
  good_night: "Good night",
  good_morning: "Good morning",
  confused: "Confused",
  chill_out: "Chill out",
  love: "Love",
  happy: "Happy",
  cry: "Cry",
  yes: "Yes",
  no: "No",
  lol: "Lol",
  excited: "Excited",
  bye: "Bye",
  sorry: "Sorry",
  congratulations: "Congratulations",
}

const categories = {
  ad: "Ad",
  anime: "Anime",
  cartoon: "Cartoon",
  meme: "Meme",
  movie: "Movie",
  show: "Show (on stage)",
  sketch: "Sketch",
  song: "Song",
  sport: "Sport",
  speech: "Speech",
  series: "TV Series",
  tv_show: "TV Show",
  video: "Video",
}

const emotions = {
  joy: "Joy",
  fear: "Fear",
  sadness: "Sadness",
  disgust: "Disgust",
  anger: "Anger",
  surprise: "Surprise",
  love: "Love",
}

const emotions2 = [
  {
    id: "joy",
    title: "Joy",
    color: "#dcf30b",
    children: [
      {
        id: "rejoicing",
        title: "Rejoicing (réjoui)",
        color: "#dcf30b",
      },
      {
        id: "amusement",
        title: "Amusement (amusé)",
        color: "#dcf30b",
      },
      {
        id: "shadenfreude",
        title: "Shadenfreude (réjoui du malheur des autres)",
        color: "#dcf30b",
      },
      {
        id: "relief",
        title: "Relief (soulagé)",
        color: "#dcf30b",
      },
      {
        id: "pride",
        title: "Pride (fier)",
        color: "#dcf30b",
      },
      {
        id: "wonder",
        title: "Wonder (émerveillé)",
        color: "#dcf30b",
      },
      {
        id: "excitement",
        title: "Excitement (exalté)",
        color: "#dcf30b",
      }
    ],
  },
  {
    id: "fear",
    title: "Fear",
    color: "red",
    children: [
      {
        id: "embarrassment",
        title: "Embarrassment (embarrassé)",
        color: "#OOffff",
      },
      {
        id: "intimidation",
        title: "Intimidation (intimidé)",
        color: "#OOffff",
      },
      {
        id: "stressed",
        title: "Stressed (stressé)",
        color: "#OOffff",
      },
      {
        id: "panic",
        title: "Panic (paniqué)",
        color: "#OOffff",
      },
      {
        id: "terror",
        title: "Terror (terrorisé)",
        color: "#OOffff",
      },
    ],
  },
  {
    id: "sadness",
    title: "Sadness",
    color: "blue",
    children: [
      {
        id: "disappointment",
        title: "Disappointment (déçu)",
        color: "blue",
      },
      {
        id: "discouragement",
        title: "Discouragement (découragé)",
        color: "blue",
      },
      {
        id: "hopelessness",
        title: "Hoplessness (désespéré)",
        color: "blue",
      },
      {
        id: "grief",
        title: "Grief (douleur)",
        color: "blue",
      },
      {
        id: "sorrow",
        title: "Sorrow (triste)",
        color: "blue",
      },
      {
        id: "shame",
        title: "Shame (honteux)",
        color: "blue",
      },
      {
        id: "guilty",
        title: "Guilty (coupable)",
        color: "blue",
      },
    ],
  },
  {
    id: "disgust",
    title: "Disgust",
    color: "purple",
    children: [
      {
        id: "dislike",
        title: "Dislike (n'aime pas)",
        color: "purple",
      },
      {
        id: "contempt",
        title: "Contempt (mépris)",
        color: "purple",
      },
      {
        id: "disillusioned",
        title: "Disillusioned (désabusé)",
        color: "purple",
      },
      {
        id: "repugnance",
        title: "Repugnance (répugnant)",
        color: "purple",
      },
      {
        id: "abhorrence",
        title: "Abhorrence (détesté)",
        color: "purple",
      },
    ],
  },
  {
    id: "anger",
    title: "Anger",
    color: "red",
    children: [
      {
        id: "annoyance",
        title: "Annoyance (contrarié)",
        color: "red",
      },
      {
        id: "frustration",
        title: "Frustration (frustré)",
        color: "red",
      },
      {
        id: "jealousy",
        title: "Jealousy (jaloux)",
        color: "red",
      },
      {
        id: "bitterness",
        title: "Bitterness (amer)",
        color: "red",
      },
      {
        id: "exasperation",
        title: "Exasperation (exaspéré)",
        color: "red",
      },
      {
        id: "vengefulness",
        title: "Vengefulness (vengeance)",
        color: "red",
      },
      {
        id: "fury",
        title: "Fury (fury)",
        color: "red",
      },
    ],
  },
  {
    id: "surprise",
    title: "Surprise",
    color: "orange",
    children: [
      {
        id: "interest",
        title: "Interest (intéressé)",
        color: "orange",
      },
      {
        id: "amazed",
        title: "Amazed (étonné)",
        color: "orange",
      },
      {
        id: "stunned",
        title: "Stunned (sonné)",
        color: "orange",
      },
      {
        id: "shocked",
        title: "Shocked (choqué)",
        color: "orange",
      },
    ],
  },
  {
    id: "love",
    title: "Love",
    color: "pink",
    children: [
      {
        id: "sympathy",
        title: "Sympathy (empathie)",
        color: "pink",
      },
      {
        id: "gratefulness",
        title: "Gratefulness (gratitude)",
        color: "pink",
      },
      {
        id: "proud",
        title: "Proud (fier)",
        color: "pink",
      },
      {
        id: "admiration",
        title: "Admiration",
        color: "pink",
      },
      {
        id: "lover",
        title: "Lover (amoureux)",
        color: "pink",
      },
    ],
  }
]

type Punchline = {
  redo: boolean;
  video: string;
  image: string;
  author: string;
  quote: string;
  status: string;
  published: boolean;
  sensitive: boolean;
  tags: string[];
  //topics: string[];
  //emotion: string[];
  emotions: string[];
  // reactions: string[];
  // attitudes: string[];
  // link: string | null | undefined;
  created_by: string | null | undefined;
  created_at: string | null | undefined;
  updated_at: string | null | undefined;
  lang: string;
  source: {
    title: string;
    categories: string[];
    type: string;
    image: string;
    audio: string;
    from: string;
    to: string;
    fromEditor: string | null | undefined;
    toEditor: string | null | undefined;
  };
};

const punchlinesCallbacks = buildEntityCallbacks<Punchline>({
  onPreSave: ({ schema, path, entityId, values, status, context }) => {
    console.log('context.authController.user', context.authController.user);
    console.log("valuesPRESAVE BEFORE", values);
    if (
      typeof values.source === 'object' &&
      typeof values.source["fromEditor"] === 'string' &&
      typeof values.source["toEditor"] === 'string'
    ) {
      values["source"]["from"] = values.source["fromEditor"]
      values["source"]["to"] = values.source["toEditor"]
      values["redo"] = true;
      delete values.source["fromEditor"];
      delete values.source["toEditor"];
    }
    if (values["created_at"] == null) {
      values["created_at"] = new Date().toISOString();
    }
    if (values["emotions"] != null && values["emotions"].length > 0) {
      values["emotions"].map(emotion => {
       console.log('emotion', emotion);
      })
    }
    values["updated_at"] = new Date().toISOString();
    values["tags"] = (values["tags"] != undefined && values["tags"].length > 0) ? values["tags"].filter(tag => tag != null) : []

    console.log('USER', context.authController.user?.email);
    //   //values["created_by"] = context.authController.user.email

    console.log("valuesPRESAVE", values);

    return values;
  },
});

const punchlineSchema = buildSchema<Punchline>({
  name: "Punchline",
  properties: {
    redo: {
      title: "Redo",
      dataType: "boolean",
      columnWidth: 100,
    },
    author: {
      title: "Author",
      validation: { required: false },
      dataType: "string",
      config: {
        Field: CustomColorTextField,
        customProps: {
          color: "gold",
        },
      },
    },
    quote: {
      title: "Punchline (text)",
      validation: { required: false },
      dataType: "string",
    },
    sensitive: {
      title: "Sensitive",
      description: "Contains insults or objectionable language",
      dataType: "boolean",
      columnWidth: 100,
    },
    emotions: {
      title: "Emotion",
      validation: { required: false },
      dataType: "array",
      config: {
        Field: CustomEmotionField,
        customProps: {
          emotion: emotions2,
        },
      },
      of: {
        dataType: "string",
      },
    },
    // emotion: {
    //   title: "Emotion",
    //   validation: { required: false },
    //   dataType: "array",
    //   description: "What is the primary emotion of this punchline?",
    //   of: {
    //     dataType: "string",
    //     config: {
    //       enumValues: emotions,
    //     },
    //   },
    // },
    // reactions: {
    //   title: "Reactions",
    //   validation: { required: false },
    //   dataType: "array",
    //   of: {
    //     dataType: "string",
    //     config: {
    //       enumValues: reactions,
    //     },
    //   },
    // },
    // attitudes: {
    //   title: "Attitudes",
    //   validation: { required: false },
    //   dataType: "array",
    //   of: {
    //     dataType: "string",
    //     config: {
    //       enumValues: attitudes,
    //     },
    //   },
    // },
    source: {
      title: "Source",
      description: "Source of the punchline",
      dataType: "map",
      properties: {
        title: {
          title: "Title",
          dataType: "string",
        },
        categories: {
          title: "Categories",
          validation: { required: false },
          dataType: "array",
          of: {
            dataType: "string",
            config: {
              enumValues: categories,
            },
          },
        },
        type: {
          title: "Type",
          dataType: "string",
        },
        image: {
          title: "image",
          dataType: "string",
        },
        audio: {
          title: "audio",
          dataType: "string",
          config: {
            Field: VideoEditor,
          },
        },
        from: {
          title: "Start (time 00:00:00)",
          dataType: "string",
        },
        to: {
          title: "End (time 00:00:00)",
          dataType: "string",
        },
      },
    },
    video: buildProperty({
      title: "Video (mp4)",
      dataType: "string",
      validation: { required: false },
      config: {
        storageMeta: {
          mediaType: "video",
          storagePath: "videos",
          acceptedFiles: ["video/*"],
        },
      },
    }),
    image: buildProperty({
      title: "Image (jpg)",
      dataType: "string",
      validation: { required: false },
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
        },
      },
    }),
    lang: {
      title: "Langue parlée",
      dataType: "string",
      validation: { required: true },
      config: {
        enumValues: {
          fr: "Français",
          en: "English",
          sp: "Español",
          it: "Italiano",
          de: "Deutsch",
        },
      },
    },
    // topics: {
    //   title: "Topics",
    //   validation: { required: false },
    //   dataType: "array",
    //   of: {
    //     dataType: "string",
    //     config: {
    //       enumValues: topics,
    //     },
    //   },
    // },
    tags: {
      title: "Tags",
      description: "Example of generic array",
      validation: { required: true },
      dataType: "array",
      of: {
        dataType: "string",
      },
    },
    status: {
      title: "Status",
      validation: { required: true },
      dataType: "string",
      description: "Should this product be visible in the website",
      longDescription:
        "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      config: {
        enumValues: {
          private: "Private",
          public: "Public",
        },
      },
    },
    published: ({ values }) =>
      buildProperty({
        title: "Published",
        dataType: "boolean",
        columnWidth: 100,
        disabled:
          values.status === "public"
            ? false
            : {
                clearOnDisabled: true,
                disabledMessage:
                  "Status must be public in order to enable this the published flag",
              },
      }),
    created_at: {
      dataType: "string",
      disabled: true,
    },
    updated_at: {
      dataType: "string",
      disabled: true,
    },
    created_by: {
      dataType: "reference",
      title: "Created by",
      path: "users",
      previewProperties: ["name"]
    },
  },
  defaultValues: {
    lang: "fr",
    published: false,
    status: "Private",
  },
});

type User = {
  email: string;
  name: string;
  username: string;
};

const userSchema = buildSchema<User>({
  name: "User",
  properties: {
    email: {
      title: "Email",
      validation: { required: false },
      dataType: "string",
    },
    name: {
      title: "Nom",
      validation: { required: false },
      dataType: "string",
    },
    username: {
      title: "Pseudo",
      validation: { required: false },
      dataType: "string",
    }
  }
});

type FilterCategory = {
  relation: string;
  categories: string[];
}

type FilterTag = {
  relation: string;
  tags: string[];
}

type FilterAuthor = {
  relation: string;
  authors: string[];
}

type FilterSourceTitle = {
  relation: string;
  titles: string[];
}

type FilterTopic = {
  relation: string;
  topics: string[];
}

type FilterEmotion = {
  relation: string;
  emotions: string[];
}

type FilterReaction = {
  relation: string;
  reactions: string[];
}

type Collection = {
  title: string;
  description: string;
  filters: Array<Array<string> | Array<FilterCategory> | Array<FilterTag> | Array<FilterAuthor> | Array<FilterSourceTitle> | Array<FilterTopic> | Array<FilterEmotion> | Array<FilterReaction>>;
  locales: [{
    lang: string;
    value: {
      title: string;
    };
  }];
};

const collectionSchema = buildSchema<Collection>({
  name: "Collection",
  properties: {
    title: {
      title: "Titre",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      validation: { required: false },
      dataType: "string",
    },
    filters: {
      title: "Filters",
      description: "Create your filter to build your collection",
      validation: { required: true },
      dataType: "array",
      columnWidth: 400,
      oneOf: {
        properties: {
          punchlines: {
            title: "Punchlines",
            validation: { required: false },
            dataType: "array",
            of: {
              dataType: "reference",
              path: "punchlines",
              previewProperties: ["quote"]
            }
          },
          filterCategory: {
            title: "Filter Category",
            description: "Filter by category",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                }
              },
              categories: {
                title: "Categories",
                validation: { required: false },
                dataType: "array",
                of: {
                  dataType: "string",
                  config: {
                    enumValues: categories,
                  }
                },
              },
            },
          },
          filterTag: {
            title: "Filter Tag",
            description: "Filter by tag",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                }
              },
              tags: {
                title: "Tags",
                validation: { required: false },
                dataType: "array",
                of: {
                  dataType: "string",
                }
              },
            },
          },
          filterAuthor: {
            title: "Filter Author",
            description: "Filter by author",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                },
              },
              authors: {
                title: "Authors",
                validation: { required: false },
                dataType: "array",
                of: {
                  dataType: "string",
                },
              },
            },
          },
          filterSourceTitle: {
            title: "Filter Source Title",
            description: "Filter by source title",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                }
              },
              titles: {
                title: "Source Titles",
                validation: { required: false },
                dataType: "array",
                of: {
                  dataType: "string",
                },
              },
            },
          },
          filterTopic: {
            title: "Filter Topic",
            description: "Filter by topic",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                }
              },
              topics: {
                title: "Topics",
                validation: { required: false },
                dataType: "array",
                of: {
                  dataType: "string",
                  config: {
                    enumValues: topics
                  }
                },
              },
            },
          },
          filterEmotion: {
            title: "Filter Emotion",
            description: "Filter by emotion",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                }
              },
              emotions: {
                title: "Emotions",
                validation: { required: false },
                dataType: "array",
                config: {
                  Field: CustomEmotionField,
                  customProps: {
                    emotion: emotions2,
                  },
                },
                of: {
                  dataType: "string",
                },
              },
            },
          },
          filterReaction: {
            title: "Filter Reaction",
            description: "Filter by reaction",
            dataType: "map",
            properties: {
              relation: {
                title: "Relation",
                dataType: "string",
                config: {
                  enumValues: {
                    in: "In",
                    not_in: "Not in",
                  },
                }
              },
              reactions: {
                title: "Reactions",
                validation: { required: false },
                dataType: "array",
                of: {
                  dataType: "string",
                  config: {
                    enumValues: reactions
                  }
                },
              },
            },
          },
        }
      }
    },
    locales: {
      title: "Locales",
      description: "Add your translations",
      validation: { required: false },
      dataType: "array",
      of: {
        dataType: "map",
        properties: {
          lang: {
            title: "Lang",
            validation: { required: true },
            dataType: "string",
            config: {
              enumValues: langs,
            },
          },
          value: {
            title: "Value",
            validation: { required: true },
            dataType: "map",
            properties: {
              title: {
                title: "Title",
                validation: { required: true },
                dataType: "string",
              },
              description: {
                title: "Description",
                validation: { required: false },
                dataType: "string",
              },
            }
          },
        }
      }
    }
  }
});

type MenuLink = {
  title: string;
  page: string;
  filters: Array<Array<string> | Array<FilterCategory> | Array<FilterTag> | Array<FilterAuthor> | Array<FilterSourceTitle> | Array<FilterTopic> | Array<FilterEmotion> | Array<FilterReaction>>;
  image: string;
  bgColor: string;
  locales: [{
    lang: string;
    value: {
      title: string;
    };
  }];
}

type PageContent = {
  carousel: string | null | undefined;
  menuLinks: Array<MenuLink>
  content: Array<Collection>;
}

type Page = {
  title: string;
  type: string;
  description: string;
  content: Array<PageContent>;
}

const pageSchema = buildSchema<Page>({
  name: "Page",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string",
    },
    type: {
      title: "Type",
      validation: { required: true },
      dataType: "string",
      description: "Type of the page",
      config: {
        enumValues: {
          homepage: "Homepage",
          landing: "Landing page",
          search: "Search page",
        },
      },
    },
    description: {
      title: "Description",
      validation: { required: false },
      dataType: "string",
    },
    content: buildProperty({
      title: "Content",
      description: "Create the content of the page",
      validation: { required: false },
      dataType: "array",
      columnWidth: 400,
      oneOf: {
        properties: {
          collection: {
            dataType: "map",
            title: "Collection",
            properties: {
              ui: {
                title: "UI",
                validation: { required: false },
                dataType: "string",
                config: {
                  enumValues: {
                    collection: "Collection",
                    carousel: "Carousel",
                    list: "Liste",
                  },
                },
              },
              collection: {
                dataType: "reference",
                title: "Collection",
                path: "collections",
                previewProperties: ["title"],
              }
            }
          },
          menuLinks: {
            title: "Menu Links",
            description: "Create your menu links",
            validation: { required: false },
            dataType: "map",
            properties: {
              ui: {
                title: "UI",
                validation: { required: false },
                dataType: "string",
                config: {
                  enumValues: {
                    menu: "Menu",
                    carousel: "Carousel",
                  },
                },
              },
              content: {
                title: "Content",
                validation: { required: false },
                dataType: "array",
                columnWidth: 400,
                of: {
                  dataType: "map",
                  properties: {
                    title: {
                      title: "Title",
                      validation: { required: true },
                      dataType: "string",
                    },
                    url: {
                      title: "URL",
                      validation: { required: false },
                      dataType: "string",
                    },
                    page: {
                      title: "Page",
                      validation: { required: false },
                      dataType: "reference",
                      path: "pages",
                      previewProperties: ["title"]
                    },
                    filters: {
                      title: "Filters",
                      description: "Create your filter to build your collection",
                      validation: { required: false },
                      dataType: "array",
                      columnWidth: 400,
                      oneOf: {
                        properties: {
                          punchlines: {
                            title: "Punchlines",
                            validation: { required: false },
                            dataType: "array",
                            of: {
                              dataType: "reference",
                              path: "punchlines",
                              previewProperties: ["quote"]
                            }
                          },
                          filterCategory: {
                            title: "Filter Category",
                            description: "Filter by category",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                }
                              },
                              categories: {
                                title: "Categories",
                                validation: { required: false },
                                dataType: "array",
                                of: {
                                  dataType: "string",
                                  config: {
                                    enumValues: categories,
                                  }
                                },
                              },
                            },
                          },
                          filterTag: {
                            title: "Filter Tag",
                            description: "Filter by tag",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                }
                              },
                              tags: {
                                title: "Tags",
                                validation: { required: false },
                                dataType: "array",
                                of: {
                                  dataType: "string",
                                }
                              },
                            },
                          },
                          filterAuthor: {
                            title: "Filter Author",
                            description: "Filter by author",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                },
                              },
                              authors: {
                                title: "Authors",
                                validation: { required: false },
                                dataType: "array",
                                of: {
                                  dataType: "string",
                                },
                              },
                            },
                          },
                          filterSourceTitle: {
                            title: "Filter Source Title",
                            description: "Filter by source title",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                }
                              },
                              titles: {
                                title: "Source Titles",
                                validation: { required: false },
                                dataType: "array",
                                of: {
                                  dataType: "string",
                                },
                              },
                            },
                          },
                          filterTopic: {
                            title: "Filter Topic",
                            description: "Filter by topic",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                }
                              },
                              topics: {
                                title: "Topics",
                                validation: { required: false },
                                dataType: "array",
                                of: {
                                  dataType: "string",
                                  config: {
                                    enumValues: topics
                                  }
                                },
                              },
                            },
                          },
                          filterEmotion: {
                            title: "Filter Emotion",
                            description: "Filter by emotion",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                }
                              },
                              emotions: {
                                title: "Emotions",
                                validation: { required: false },
                                dataType: "array",
                                config: {
                                  Field: CustomEmotionField,
                                  customProps: {
                                    emotion: emotions2,
                                  },
                                },
                                of: {
                                  dataType: "string",
                                },
                              },
                            },
                          },
                          filterReaction: {
                            title: "Filter Reaction",
                            description: "Filter by reaction",
                            dataType: "map",
                            properties: {
                              relation: {
                                title: "Relation",
                                dataType: "string",
                                config: {
                                  enumValues: {
                                    in: "In",
                                    not_in: "Not in",
                                  },
                                }
                              },
                              reactions: {
                                title: "Reactions",
                                validation: { required: false },
                                dataType: "array",
                                of: {
                                  dataType: "string",
                                  config: {
                                    enumValues: reactions
                                  }
                                },
                              },
                            },
                          }
                        }
                      }
                    },
                    image: buildProperty({
                      title: "Image (jpg)",
                      dataType: "string",
                      validation: { required: true },
                      config: {
                        storageMeta: {
                          mediaType: "image",
                          storagePath: "images",
                          acceptedFiles: ["image/*"],
                        },
                      },
                    }),
                    bgColor: {
                      title: "Background Color",
                      validation: { required: false },
                      dataType: "string",
                    },
                    locales: {
                      title: "Locales",
                      description: "Add your translations",
                      validation: { required: false },
                      dataType: "array",
                      of: {
                        dataType: "map",
                        properties: {
                          lang: {
                            title: "Lang",
                            validation: { required: true },
                            dataType: "string",
                            config: {
                              enumValues: langs,
                            },
                          },
                          value: {
                            title: "Value",
                            validation: { required: true },
                            dataType: "map",
                            properties: {
                              title: {
                                title: "Title",
                                validation: { required: true },
                                dataType: "string",
                              },
                              url: {
                                title: "Url",
                                validation: { required: false },
                                dataType: "string",
                              }
                            }
                          },
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          collections: {
            title: "Collections",
            validation: { required: false },
            dataType: "array",
            of: {
              dataType: "reference",
              path: "collections",
              previewProperties: ["title"]
            }
          }
        }
      }
    })
  }
});

export function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    // This is a fake example of retrieving async data related to the user
    // and storing it in the authController
    const sampleUser = await Promise.resolve({
      name: "John",
      roles: ["admin"],
    });
    authController.setExtra(sampleUser);

    return {
      collections: [
        buildCollection<Punchline>({
          path: "punchlines",
          schema: punchlineSchema,
          filterCombinations: [{ status: "desc", published: "desc" }],
          name: "Punchlines",
          callbacks: punchlinesCallbacks,
          textSearchEnabled: true,
          //textSearchDelegate: punchlinesSearchDelegate,
          permissions: ({ user, authController }) => ({
            edit: true,
            create: true,
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection<User>({
          path: "users",
          schema: userSchema,
          name: "Users",
          textSearchEnabled: true,
          permissions: ({ user, authController }) => ({
            edit: true,
            create: true,
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection<Collection>({
          path: "collections",
          schema: collectionSchema,
          name: "Collections",
          textSearchEnabled: true,
          permissions: ({ user, authController }) => ({
            edit: true,
            create: true,
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection<Page>({
          path: "pages",
          schema: pageSchema,
          name: "Pages",
          textSearchEnabled: false,
          permissions: ({ user, authController }) => ({
            edit: true,
            create: true,
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator = async ({ user,authController }) => {
    console.log("Allowing access to", user?.email);
    const email = user && user.email ? user.email : "";
    // const sampleUserData = await Promise.resolve({
    //     roles: ["admin"]
    // });
    // authController.setExtra(sampleUserData);
    // const isAdmin = !!(await (user as any).getIdTokenResult())?.claims?.admin;
    // authController.setExtra({ isAdmin });
    // return true;

    const admins = [
      "bessong@gmail.com",
      "djel31130@gmail.com",
      "tara.besson@gmail.com",
      "theo.besson@gmail.com",
      "tamsi.besson@gmail.com",
      "laure.franceschini@gmail.com",
      "emmanuel.gonzalez@livingcolor.fr",
      "clementinebesson22@gmail.com",
      "christophe.atienza@gmail.com",
      "tom.fontaine31130@gmail.com",
      "tara@punchline.club",
      "theo@punchline.club",
      "pierre@punchline.club",
      "greg@punchline.club",
    ];
    if (admins.includes(email)) {
      return true;
    }

    return false;
  };

  return (
    <PunchlineCMSApp
      name={"Punchline CMS"}
      authentication={myAuthenticator}
      allowSkipLogin={false}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
      textSearchController={textSearchController}
    />
  );
}
