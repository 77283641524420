export  const formatTimecode = (duration, position) => {
  const timey = position * duration
  const s = Math.trunc(timey)
  const images = Math.trunc((timey - s) * 60)
  const date = new Date(s * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())

  if (hh) {
    return `${pad(hh)}:${pad(mm)}:${ss}.${pad(images)}`
  }
  return `00:${pad(mm)}:${ss}.${pad(images)}`
}

export const pad = (string) => {
  return ('0' + string).slice(-2)
}